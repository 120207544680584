var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"fixed left-0 top-[3rem] xl:top-[9rem] w-full"},[_c('div',{staticClass:"bg-white xl:bg-transparent search-dropdown z-30 container lg:px-0 overflow-y-scroll overflow-x-hidden lg:overflow-visible"},[_c('div',{staticClass:"h-screen lg:h-auto lg:overflow-y-scroll nicescroll-medium xl:-ml-6 xl:w-[calc(100%+3rem)] relative"},[_c('div',{staticClass:"grid grid-cols-12 xl:rounded-md xl:overflow-hidden"},[_c('div',{staticClass:"col-span-12 lg:col-span-4 bg-blue-900 pt-14 pb-10 md:pb-12 lg:pb-24 search-form-wrapper lg:pr-12 h-max lg:h-full relative lg:pl-8"},[_c('button',{staticClass:"close-btn close-btn--white flex lg:hidden z-1",on:{"click":_vm.closeDropdown}}),_vm._v(" "),_c('span',{staticClass:"font-Inter-Bold text-2xl mb-5 text-white w-full text-center lg:text-left lg:w-auto"},[_vm._v("Keresés")]),_vm._v(" "),_c('div',{staticClass:"search-input-wrapper flex items-center relative w-full z-10 lg:mb-8",class:!_vm.thin && 'search-icon'},[_c('div',{ref:"inputContainer",staticClass:"w-full"},[_c('InputText',{ref:"input",class:_vm.thin ? 'border-none bg-transparent pl-6' : 'pl-12',attrs:{"id":"searchInput","placeholder":"Írd ide a keresett kifejezést!"},on:{"input":_vm.search,"focus":function($event){return _vm.$store.commit('toggleSearchDropdown', true)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]),_vm._v(" "),(
                                !_vm.results.products.length && !_vm.results.categories.length && !_vm.results.articles.length
                            )?_c('div',{staticClass:"hidden lg:block"},[_c('span',{staticClass:"font-Inter-Semibold text-base mb-2 text-white items-center block"},[_vm._v("Használat")]),_vm._v(" "),_c('span',{staticClass:"font-Inter-Regular text-xs leading-5 text-white block"},[_vm._v("A kereső használatához kezdj el gépelni legalább három karaktert. A rendszer\n                                azonnal megjeleníti a releváns cikkeket, kategóriákat és termékeket a beírt szavak\n                                alapján.\n                            ")])]):_vm._e(),_vm._v(" "),(_vm.$device.isDesktop)?_c('div',{staticClass:"hidden md:flex flex-col mt-4"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.products.length),expression:"results.products.length"}],class:_vm.results.categories.length && 'mb-6'},[_c('span',{staticClass:"block w-full text-left font-Inter-Bold text-base pb-3 border-b border-dashed border-white border-opacity-20 mb-4 xl:pl-0 text-white"},[_vm._v("Termékek")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-y-3"},_vm._l((_vm.results.products.slice(0, 3)),function(product){return _c('div',{key:product?.id,on:{"click":_vm.closeDropdown}},[_c('nuxt-link',{staticClass:"font-Inter-Regular text-white hover:underline",attrs:{"to":_vm.localePath({
                                                    name: 'products-category-product',
                                                    params: {
                                                        category: product?.category_slug,
                                                        product: product?.slug,
                                                    },
                                                })}},[_vm._v(_vm._s(product.name)+"\n                                        ")])],1)}),0)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.categories.length),expression:"results.categories.length"}],class:_vm.results.articles.length && 'mb-6'},[_c('span',{staticClass:"block w-full text-left font-Inter-Bold text-base pb-3 border-b border-dashed border-white border-opacity-20 mb-4 xl:pl-0 text-white"},[_vm._v("Kategóriák")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-y-3"},[_c('div',{staticClass:"h-full",on:{"click":_vm.closeDropdown}},_vm._l((_vm.results.categories),function(category){return _c('nuxt-link',{key:`category-${category?.id}`,staticClass:"font-Inter-Regular text-white hover:underline",attrs:{"to":_vm.localePath({
                                                    name: 'products-category',
                                                    params: { category: category.translations[0].slug },
                                                })}},[_vm._v(_vm._s(category.translations[0]?.name)+"\n                                        ")])}),1)])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.articles.length),expression:"results.articles.length"}]},[_c('span',{staticClass:"block w-full text-left font-Inter-Bold text-base pb-3 border-b border-dashed border-white border-opacity-20 mb-4 xl:pl-0 text-white"},[_vm._v("Magazin")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-y-3"},[_c('div',{staticClass:"h-full",on:{"click":_vm.closeDropdown}},_vm._l((_vm.results.articles.slice(0, 10)),function(article,i){return _c('nuxt-link',{key:`article-${i}`,staticClass:"font-Inter-Regular text-white hover:underline",attrs:{"to":_vm.localePath({
                                                    name: 'magazine-article',
                                                    params: {
                                                        article: article.slug,
                                                    },
                                                })}},[_vm._v(_vm._s(article.title.slice(0, 32))+"...\n                                        ")])}),1)])])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-span-12 lg:col-span-8 bg-white pt-2 lg:pt-14 pb-12 lg:pb-24 lg:pl-4 lg:pr-4 relative max-h-screen lg:w-auto lg:overflow-y-scroll nicescroll-medium",class:!_vm.results.products.length && !_vm.results.categories.length && !_vm.results.articles.length
                                ? 'flex items-center'
                                : ''},[_c('div',{staticClass:"full rounded-sm bg-white lg:px-4 font-Inter-Bold w-full transition-all lg:overflow-y-scroll nicescroll-medium",class:{
                                'lg:pb-20':
                                    Object.values(_vm.results).filter((property) => property.length > 0).length > 1,
                                'lg:pb-0':
                                    Object.values(_vm.results).filter((property) => property.length > 0).length === 1,
                                'pb-0':
                                    Object.values(_vm.results).filter((property) => property.length > 0).length === 0,
                            }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.products.length),expression:"results.products.length"}],class:(_vm.results.articles.length || _vm.results.categories.length) && 'mb-8'},[_c('span',{staticClass:"block w-full text-left font-Inter-Bold text-xl pb-3 border-b border-dashed border-black border-opacity-20 mb-6 xl:pl-0 mt-4 lg:mt-0"},[_vm._v("Termékek")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-4 lg:grid-cols-3 gap-x-4 gap-y-4 lg:gap-y-0"},[_vm._l((_vm.results.products.slice(0, 3)),function(product){return _c('div',{key:`product-${product.id}`,staticClass:"col-span-2 lg:col-span-1"},[_c('div',{on:{"click":_vm.closeDropdown}},[_c('ProductCard',{staticClass:"min-h-[25.4rem]",attrs:{"title":product.name,"product":product,"has-btn-text":false,"slug":_vm.localePath({
                                                        name: 'products-category-product',
                                                        params: {
                                                            category: product?.category_slug,
                                                            product: product?.slug,
                                                        },
                                                    }),"brutto-price":product.price,"is-favorite":product.isFavorite,"price":product.price,"discounted-price":product.discounted_price,"image":product.category_name === 'Whispers of Wood'
                                                        ? product.image
                                                        : product.preview,"md":""}})],1)])}),_vm._v(" "),(_vm.results.products.length > 3)?_c('div',{staticClass:"col-span-5 lg:col-span-3 flex justify-center -mt-2 lg:mb-0 lg:mt-4"},[_c('div',{on:{"click":_vm.closeDropdown}},[_c('more-button',{attrs:{"to":_vm.localePath('products'),"label":"További termékek"}})],1)]):_vm._e()],2)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.articles.length),expression:"results.articles.length"}],class:_vm.results.categories.length && 'mb-8'},[_c('span',{staticClass:"block w-full text-left font-Inter-Bold text-xl pb-3 border-b border-dashed border-black border-opacity-20 mb-6 xl:pl-0 mt-4 lg:mt-0"},[_vm._v("Magazin")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-3 gap-x-4"},[_vm._l((_vm.results.articles.slice(0, 3)),function(article){return _c('div',{key:`product-${article.id}`,staticClass:"col-span-3 lg:col-span-1"},[_c('div',{staticClass:"h-full",on:{"click":_vm.closeDropdown}},[_c('ArticleCard',{attrs:{"title":article.title,"img-src":article.image,"description":`${article.lead.slice(0, 60)}...`,"url":_vm.localePath({
                                                        name: 'magazine-article',
                                                        params: {
                                                            article: article.slug,
                                                        },
                                                    }),"category":article.category,"minutes":"5","publish-date":article.public_date,"md":""}})],1)])}),_vm._v(" "),(_vm.results.articles.length > 3)?_c('div',{staticClass:"col-span-3 flex justify-center mt-8",on:{"click":_vm.closeDropdown}},[_c('more-button',{attrs:{"to":_vm.localePath('magazine'),"label":"További cikkek"}})],1):_vm._e()],2)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.results.categories.length),expression:"results.categories.length"}]},[_c('span',{staticClass:"block w-full text-left font-Inter-Bold text-xl pb-3 border-b border-dashed border-black border-opacity-20 mb-6 xl:pl-0 mt-4 lg:mt-0"},[_vm._v("Kategóriák")]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap gap-5 mt-7"},[_c('div',{on:{"click":_vm.closeDropdown}},_vm._l((_vm.results.categories),function(category){return _c('nuxt-link',{key:`category-${category?.id}`,staticClass:"py-2 px-6 rounded-full font-Inter-Bold text-blue-100 bg-blue-100 bg-opacity-5 border border-blue-100 border-opacity-60 hover:border-opacity-100",attrs:{"to":_vm.localePath({
                                                    name: 'products-category',
                                                    params: { category: category.translations[0].slug },
                                                })}},[_vm._v("\n                                            "+_vm._s(category.translations[0]?.name)+"\n                                        ")])}),1)])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                    !_vm.results.categories.length &&
                                    !_vm.results.products.length &&
                                    !_vm.results.articles.length
                                ),expression:"\n                                    !results.categories.length &&\n                                    !results.products.length &&\n                                    !results.articles.length\n                                "}],staticClass:"w-full text-center flex flex-col justify-center items-center mt-[25%] lg:mt-0"},[_c('i',{staticClass:"block text-3xl lg:text-[2.25rem] mb-2 lg:mb-3",class:_vm.handleFeedbackIcon}),_vm._v(" "),(_vm.noResults && _vm.searchText.length > 3)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{staticClass:"text-lg block mb-2"},[_vm._v("Sajnos nem találtunk a keresésnek megfelelő elemet")]),_vm._v(" "),_c('span',{staticClass:"text-sm font-Inter-Regular block"},[_vm._v("Próbálj meg más kifejezéssel keresni címre vagy kategóriára.")])]):_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{staticClass:"text-lg block mb-2"},[_vm._v("A keresés indításához kérlek kezdj el gépelni legalább 3 karaktert!")]),_vm._v(" "),_c('span',{staticClass:"text-sm font-Inter-Regular block"},[_vm._v("Cikkek címében, kategóriákban és termékek nevében.")])])])])])]),_vm._v(" "),_c('button',{staticClass:"close-btn hidden lg:block",on:{"click":_vm.closeDropdown}})])]),_vm._v(" "),_c('div',{staticClass:"fixed -top-6 left-0 w-full h-full lg:min-h-[calc(100vh+1.5rem)] bg-black opacity-0 z-[-1] overlay min-h-screen",class:{ 'pointer-events-none': _vm.arePointerEventsDisabledOnBgOverlay },on:{"click":_vm.closeDropdown}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }