<template>
    <div class="relative lg:flex items-center md:items-baseline rounded-full xl:border-none flex">
        <div
            v-if="isSimple"
            class="transition font-Inter-Semibold text-center rounded-full bg-yellow-100 hover:bg-yellow-200 p-0 justify-center text-black flex items-center text-lg opacity-100 cursor-pointer"
            :class="
                md ? 'h-12 w-12 max-h-12 max-w-12 min-h-12 min-w-12' : 'h-16 w-16 min-h-16 min-w-16 max-h-16 max-w-16'
            "
            @click="cb"
        >
            <i class="ri-shopping-cart-line text-2xl" :class="md ? 'text-xl' : 'text-2xl'"></i>
        </div>
        <nuxt-link
            v-else
            to="/rendeles/kosar"
            class="transition font-Inter-Semibold text-center rounded-full lg:bg-yellow-100 lg:hover:bg-yellow-200 text-black flex items-center text-lg sm:py-3 sm:px-4 lg:px-8 h-auto w-auto"
            :class="
                cartItemCount || $store.state.checkout.cartItemsCount ? 'opacity-100' : 'opacity-40 pointer-events-none'
            "
            ><i class="ri-shopping-cart-line lg:mr-2 text-[1.6rem] lg:text-lg w-full lg:w-auto"></i
            ><span class="hidden lg:block">Kosár</span></nuxt-link
        >
        <div
            v-if="cartItemCount || $store.state.checkout.cartItemsCount"
            class="h-5 lg:h-8 w-5 lg:w-8 rounded-full text-white bg-red-100 font-Inter-Bold text-sm md:text-base flex items-center justify-center text-center leading-none absolute -top-2 sm:top-0 xl:-top-2 -right-3 sm:right-0 lg:-right-2 pointer-events-none"
        >
            <transition name="fade-in-value" mode="out-in">
                <span :key="$store.state.checkout.cartItemsCount">
                    {{ cartItemCount || $store.state.checkout.cartItemsCount }}
                </span>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CartButton',
    props: {
        isSimple: {
            type: Boolean,
            required: false,
            default: false,
        },
        md: {
            type: Boolean,
            required: false,
            default: false,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
        cb: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            cartItemCount: null,
        };
    },
    async fetch() {
        try {
            await this.$axios.get(`/cart/${this.$cookies.get('hsession')}`).then((res) => {
                if (res.data.success !== true) return;
                this.cartItemCount = res.data.products.length;
            });
        } catch (err) {
            console.log(err);
        }
    },
    watch: {
        '$store.state.checkout.cartItemsCount'(value) {
            this.cartItemCount = value;
        },
    },
};
</script>

<style scoped></style>
