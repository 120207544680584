<template>
    <div class="relative">
        <h3 class="font-Inter-Bold text-[1.5rem] lg:text-2xl mb-3">
            {{ isPasswordForgotten ? 'Elfelejtett jelszó' : 'Bejelentkezés' }}
        </h3>
        <h4 class="font-Inter-Regular text-sm lg:text-base leading-5 mb-6 lg:mb-9">
            {{ isPasswordForgotten ? 'Adja meg új jelszavát!' : 'Lépjen be az e-mail címe segítségével!' }}
        </h4>
        <ValidationObserver v-if="isPasswordForgotten" ref="resetPasswordForm" slim>
            <form novalidate class="w-full lg:w-8/12 mx-auto" @submit.prevent="resetPassword">
                <div class="flex flex-col gap-y-4">
                    <div>
                        <ValidationProvider v-slot="{ errors }" tag="div" vid="email" class="w-full">
                            <Input
                                v-model="resetPasswordEmail"
                                class="w-full"
                                label="Email"
                                placeholder="Add meg az email címed"
                                :error="errors"
                                :is-error-visible="isErrorVisible"
                            />
                        </ValidationProvider>
                        <div class="w-full flex justify-end" @click="toggleNewPassword()">
                            <span class="font-Inter-Semibold hover:underline mt-4 cursor-pointer"
                                >Vissza a bejelentkezéshez</span
                            >
                        </div>
                    </div>
                    <PButton
                        appearance="primary"
                        class="mt-6 w-full lg:w-max mx-auto px-12 min-w-[10rem]"
                        :has-preloader="isPasswordResetLoading"
                        label="Kérelem küldése"
                    />
                </div>
            </form>
        </ValidationObserver>
        <ValidationObserver v-else ref="loginForm" slim>
            <form novalidate class="w-full lg:w-8/12 mx-auto" @submit.prevent="login">
                <div class="flex flex-col gap-y-4">
                    <ValidationProvider id="emailInput" v-slot="{ errors }" tag="div" vid="username" class="w-full">
                        <Input
                            v-model="email"
                            class="w-full"
                            label="Email"
                            placeholder="Add meg az email címed"
                            :error="errors"
                            :is-error-visible="isErrorVisible"
                            @input="formatEmail"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" tag="div" vid="password" class="w-full">
                        <Input
                            v-model="password"
                            class="w-full"
                            is-password
                            placeholder="Add meg jelszavad"
                            label="Jelszó"
                            :error="errors"
                            :is-error-visible="isErrorVisible"
                        />

                        <div class="w-full flex justify-end" @click="toggleNewPassword()">
                            <span class="font-Inter-Semibold hover:underline mt-4 cursor-pointer"
                                >Elfelejtetted a jelszavad?</span
                            >
                        </div>
                    </ValidationProvider>
                    <PButton
                        appearance="primary"
                        class="mt-6 w-full lg:w-max mx-auto px-12 min-w-[10rem]"
                        :has-preloader="isLoginLoading"
                        label="Belépés"
                    />
                </div>
            </form>
        </ValidationObserver>
        <transition name="fade">
            <div
                v-if="isFeedbackMessageVisible"
                class="absolute top-0 left-0 w-full h-full bg-grey-25 rounded-md bg-opacity-100 flex items-center justify-center lg:p-8"
            >
                <div class="text-center flex flex-col justify-center items-center">
                    <i class="ri-checkbox-circle-fill text-4xl mb-2 text-green"></i>
                    <span class="mb-4 text-black text-[1.5rem] lg:text-2xl font-Inter-Bold"
                        >Köszönjük a kérelmedet!</span
                    >
                    <div class="flex-col">
                        <span class="text-black block">
                            A jelszó megváltoztatáshoz szükséges információkat elküldtük a megadott email címre.
                        </span>
                        <span v-if="email" class="text-black block font-Inter-Bold mt-3">&nbsp;({{ email }})</span>
                    </div>
                    <div @click="hideFeedbackMessage">
                        <PButton label="Rendben" appearance="black" class="w-max mt-8" />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'LoginForm',
    data() {
        return {
            isPasswordForgotten: false,
            email: null,
            password: null,
            isErrorVisible: false,
            resetPasswordEmail: null,
            isLoginLoading: false,
            isPasswordResetLoading: false,
            isFeedbackMessageVisible: false,
        };
    },
    mounted() {
        setTimeout(() => {
            document.querySelector('#emailInput input').focus();
        }, 250);
    },
    methods: {
        async login() {
            this.isErrorVisible = true;
            this.isLoginLoading = true;

            try {
                const response = await this.$auth.loginWith('local', {
                    data: {
                        username: this.email,
                        password: this.password,
                    },
                });
                if (response) {
                    this.isLoginLoading = false;
                    if (response.data.success === false) {
                        this.$refs.loginForm.setErrors(response.data.error);
                    } else {
                        this.$store.commit('globals/toggleLoginModal', false);
                        if (this.$device.isDesktop) {
                            this.$store.commit('globals/setHeaderThin', true);
                            setTimeout(() => {
                                this.$store.commit('globals/setHeaderThin', false);
                            }, 750);
                        } else {
                            this.$store.commit('globals/toggleMobileMenu', false);
                        }
                        setTimeout(() => {
                            this.$store.commit('globals/setNotifications', {
                                title: 'Sikeres bejelentkezés',
                                subtitle: 'Kezeld rendeléseidet, jelölj kedvenceket vagy frissítsd adataidat!',
                                emoji: 'waving_hand_emoji.png',
                            });
                        }, 1000);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async resetPassword() {
            this.isErrorVisible = true;
            this.isPasswordResetLoading = true;
            try {
                await this.$axios.$post('/user-password-reset', { email: this.resetPasswordEmail }).then((res) => {
                    this.isPasswordResetLoading = false;
                    if (res.success === true) {
                        this.isFeedbackMessageVisible = true;
                        console.log(res);
                    } else {
                        this.$refs.resetPasswordForm.setErrors(res.error);
                    }
                });
            } catch (err) {
                console.log(err);
            }
        },
        formatEmail() {
            if (this.email) {
                this.email = this.email.toLowerCase();
            }
        },
        toggleNewPassword() {
            this.isPasswordForgotten = !this.isPasswordForgotten;
        },
        hideFeedbackMessage() {
            this.isFeedbackMessageVisible = false;
            this.resetPasswordEmail = '';
        },
    },
};
</script>
