<template>
    <div class="relative">
        <ValidationObserver ref="form" slim>
            <form novalidate class="w-full lg:w-9/12 mx-auto" @submit.prevent="onSubmit">
                <div class="flex flex-col transition-all gap-y-4">
                    <div class="flex flex-col lg:flex-row gap-y-4 lg:gap-y-0 lg:gap-x-4 w-full">
                        <ValidationProvider v-slot="{ errors }" vid="first_name" tag="div" class="w-full lg:w-1/2">
                            <Input
                                v-model="last_name"
                                placeholder="Add meg a vezetékneved!"
                                label="Vezetéknév"
                                :error="errors"
                                :is-error-visible="isErrorVisible"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" vid="last_name" tag="div" class="w-full lg:w-1/2">
                            <Input
                                v-model="first_name"
                                class="w-full"
                                placeholder="Add meg a keresztneved!"
                                label="Keresztnév"
                                :error="errors"
                                :is-error-visible="isErrorVisible"
                            />
                        </ValidationProvider>
                    </div>
                    <ValidationProvider v-slot="{ errors }" tag="div" vid="email" class="w-full">
                        <Input
                            v-model="email"
                            class="w-full"
                            placeholder="Adja meg email címét"
                            label="Email"
                            :error="errors"
                            :is-error-visible="isErrorVisible"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" tag="div" vid="password">
                        <Input
                            v-model="password"
                            class="w-full"
                            placeholder="Adja meg jelszavát"
                            label="Jelszó"
                            is-password
                            :error="errors"
                            :is-error-visible="isErrorVisible"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" tag="div" vid="password_repeat">
                        <Input
                            v-model="password_repeat"
                            class="w-full"
                            placeholder="Adja meg jelszavát újra"
                            label="Jelszó újra"
                            is-password
                            :error="errors"
                            :is-error-visible="isErrorVisible"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" vid="accept_privacy_policy" tag="div">
                        <Input
                            v-model="accept_privacy_policy"
                            is-checkbox
                            :error="errors"
                            label="Elolvastam és elfogadom az oldal <a href='/adatvedelmi-nyilatkozat' target='_blank'>Adatvédelmi irányelveit.</a>"
                            :is-error-visible="isErrorVisible"
                        />
                    </ValidationProvider>
                    <ValidationProvider v-if="false" vid="newsletter_signup" tag="div">
                        <Input
                            v-model="newsletter_signup"
                            is-checkbox
                            label="Szeretnék feliratkozni a Kartapolis hírlevelére."
                        />
                    </ValidationProvider>
                    <p-button
                        type="submit"
                        appearance="simple"
                        class="mt-6 w-full lg:w-max px-12 mx-auto min-w-[11.75rem]"
                        label="Regisztráció"
                        :has-preloader="isLoading"
                    />
                </div>
            </form>
        </ValidationObserver>
        <transition name="fade">
            <div
                v-if="isFeedbackMessageVisible"
                class="absolute top-0 left-0 w-full h-full bg-white rounded-md bg-opacity-100 flex items-center justify-center lg:p-8"
            >
                <div class="text-center flex flex-col justify-center items-center">
                    <i class="ri-checkbox-circle-fill text-4xl mb-2 text-green"></i>
                    <span class="mb-4 text-black text-[1.5rem] lg:text-2xl font-Inter-Bold"
                        >Köszönjük a regisztrációdat!</span
                    >
                    <div class="flex-col">
                        <span class="text-black block">
                            Üdvözlünk a Kartapolis-on, most már élvezheted az exkluzív ajánlatokat és a könnyű vásárlás
                            élményét!”
                        </span>
                        <span v-if="email" class="text-black block font-Inter-Bold mt-3">&nbsp;({{ email }})</span>
                    </div>
                    <div @click="hideFeedbackMessage">
                        <PButton label="Bejelentkezek" appearance="black" class="w-max mt-8" />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'RegistrationForm',
    data() {
        return {
            first_name: null,
            last_name: null,
            password: null,
            password_repeat: null,
            email: null,
            accept_privacy_policy: null,
            newsletter_signup: false,
            isFeedbackMessageVisible: false,
            isErrorVisible: false,
            isLoading: false,
        };
    },
    methods: {
        async onSubmit() {
            this.isErrorVisible = true;
            this.isLoading = true;
            try {
                await this.$axios
                    .$post('/registration', {
                        first_name: this.first_name,
                        last_name: this.first_name,
                        email: this.email,
                        password: this.password,
                        password_repeat: this.password_repeat,
                        accept_privacy_policy: this.accept_privacy_policy ? 1 : 0,
                        newsletter_signup: this.newsletter_signup ? 1 : 0,
                    })
                    .then((res) => {
                        this.isLoading = false;
                        if (res.success === true) {
                            this.isFeedbackMessageVisible = true;
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                    });

                await this.$recaptcha.reset();
            } catch (err) {
                console.log(err);
            }
        },

        hideFeedbackMessage() {
            this.isFeedbackMessageVisible = false;
            this.first_name = '';
            this.last_name = '';
            this.email = '';
            this.password = '';
            this.password_repeat = '';
            this.accept_privacy_policy = false;

            this.$store.commit('globals/toggleLoginModal', true);
            this.$store.commit('globals/toggleRegistrationModal', false);
        },
    },
};
</script>
