<template>
    <BaseModal :close="close" :is-open="isOpen">
        <div
            class="bg-white rounded-t-xl lg:rounded-md w-screen lg:w-[78vw] xl:w-[50vw] max-h-[88vh] lg:min-h-[75vh] overflow-y-scroll lg:h-auto px-6 pt-9 pb-11 lg:p-16 lg:pb-24 nicescroll-medium"
        >
            <div class="flex flex-col lg:justify-start text-center h-[calc(88vh-8rem)]">
                <div>
                    <h3 class="font-Inter-Bold text-[1.5rem] lg:text-2xl mb-3">Profilom</h3>
                    <h4 class="font-Inter-Regular text-sm leading-5 lg:w-9/12 lg:mx-auto">
                        Az alábbi felületen lehetősége van módosítani adatait, megtekinteni rendeléseit és megtekinteni
                        kedvenc termékeit.
                    </h4>
                    <div
                        class="flex justify-start lg:justify-evenly mt-4 lg:mt-6 mb-5 lg:mb-7 border-b border-black border-opacity-10 w-full gap-x-2 lg:gap-x-4 nicescroll-medium overflow-x-scroll lg:overflow-x-visible w-[calc(100%+3rem)] -ml-6 lg:ml-0 lg:w-auto pl-5 lg:pl-0"
                    >
                        <div
                            v-for="tab in userTabs"
                            :key="`tab-${tab.id}`"
                            class="flex relative items-center py-3 px-5 gap-x-2 tab font-Inter-Bold cursor-pointer xl:text-base"
                            :class="[`tab--${tab.icon}`, currentUserTab === tab.id && 'tab--active']"
                            @click="changeTab(tab.id)"
                        >
                            {{ tab.name }}
                        </div>
                    </div>
                </div>
                <ValidationObserver
                    v-if="currentUserTab === 1 && processedUserData"
                    v-slot="{ errors }"
                    ref="form"
                    tag="div"
                    slim
                >
                    <form novalidate @submit.prevent="changeUserData()">
                        <div class="grid grid-cols-2 gap-x-11 gap-y-5 mb-12">
                            <div class="col-span-2">
                                <h3
                                    class="font-Inter-Bold text-base lg:text-lg mt-4 mb-2 w-full border-b border-black border-opacity-5 pb-2 text-left"
                                >
                                    Szállítási adatok
                                </h3>
                            </div>

                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="last_name" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.last_name"
                                        placeholder="Vezetéknév"
                                        :error="errors.last_name"
                                        label="Vezetéknév"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="first_name" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.first_name"
                                        placeholder="Keresztnév"
                                        :error="errors.first_name"
                                        label="Keresztnév"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="email" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.email"
                                        placeholder="Email cím"
                                        :error="errors.email"
                                        label="Email cím"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="phone" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.phone"
                                        placeholder="Telefonszám"
                                        :error="errors.phone"
                                        label="Telefonszám"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="country" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.country"
                                        is-dropdown
                                        :options="countries"
                                        placeholder="Ország"
                                        :error="errors.country"
                                        label="Ország"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="zip_code" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.zip_code"
                                        placeholder="Irányítószám"
                                        :error="errors.zip_code"
                                        label="Irányítószám"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="city" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.city"
                                        placeholder="Város"
                                        :error="errors.city"
                                        label="Város"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="address" tag="div">
                                    <Input
                                        v-model="processedUserData.shipping_details.address"
                                        placeholder="Utca, házszám"
                                        :error="errors.address"
                                        label="Utca, házszám"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2">
                                <h3
                                    class="font-Inter-Bold text-base lg:text-lg mt-4 mb-2 w-full border-b border-black border-opacity-5 pb-2 text-left"
                                >
                                    Számlázási adatok
                                </h3>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="last_name" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.last_name"
                                        placeholder="Vezetéknév"
                                        :error="errors.last_name"
                                        label="Vezetéknév"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="first_name" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.first_name"
                                        placeholder="Keresztnév"
                                        :error="errors.first_name"
                                        label="Keresztnév"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="email" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.email"
                                        placeholder="Email cím"
                                        :error="errors.email"
                                        label="Email cím"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="phone" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.phone"
                                        placeholder="Telefonszám"
                                        :error="errors.phone"
                                        label="Telefonszám"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="country" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.country"
                                        is-dropdown
                                        :options="countries"
                                        placeholder="Ország"
                                        :error="errors.country"
                                        label="Ország"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="zip_code" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.zip_code"
                                        placeholder="Irányítószám"
                                        :error="errors.zip_code"
                                        label="Irányítószám"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="city" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.city"
                                        placeholder="Város"
                                        :error="errors.city"
                                        label="Város"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 lg:col-span-1">
                                <ValidationProvider vid="address" tag="div">
                                    <Input
                                        v-model="processedUserData.invoice_details.address"
                                        placeholder="Utca, házszám"
                                        :error="errors.address"
                                        label="Utca, házszám"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2">
                                <ValidationProvider vid="privacy" tag="div">
                                    <Input
                                        v-model="processedUserData.privacy"
                                        is-checkbox
                                        :error="errors.privacy"
                                        label="Elolvastam és elfogadom az oldal <a href='/adatvedelmi-nyilatkozat' target='_blank'>Adatvédelmi irányelveit.</a>"
                                        :is-error-visible="isErrorVisible"
                                    />
                                </ValidationProvider>
                            </div>
                            <div class="col-span-2 mt-8">
                                <PButton class="w-full lg:w-auto" label="Adataim módosítása" appearance="simple" />
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
                <div v-else-if="currentUserTab === 2">
                    <div v-if="processedUserData" class="flex flex-col gap-y-4">
                        <div v-for="order in processedUserData?.orders" :key="order.id">
                            <Accordion class="accordion-custom text-black">
                                <AccordionTab>
                                    <template #header>
                                        <div
                                            class="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row justify-start lg:justify-center lg:items-center text-base text-left mr-12 lg:mr-0 xl:w-full"
                                        >
                                            <div class="flex items-center lg:pr-4 xl:w-4/12 2xl:whitespace-nowrap">
                                                <span class="block"
                                                    ><span class="font-Inter-Bold">Státusz: &nbsp; </span
                                                    ><span
                                                        class="font-Inter-Semibold"
                                                        :class="coloringStatus(order.status)"
                                                        >{{ order.status }}</span
                                                    ></span
                                                >
                                            </div>
                                            <div
                                                class="flex items-center lg:pl-4 lg:pr-4 lg:border-r border-black border-opacity-10 xl:w-4/12"
                                            >
                                                <span class="block"
                                                    ><span class="font-Inter-Bold">ID: &nbsp; </span
                                                    >{{ order.order_id }}</span
                                                >
                                            </div>
                                            <div class="flex items-center lg:pl-4 lg:pr-4 xl:w-4/12">
                                                <span class="block"
                                                    ><span class="font-Inter-Bold">Dátum: &nbsp; </span
                                                    >{{ order.order_date }}</span
                                                >
                                            </div>
                                            <div v-if="false" class="flex items-center lg:pl-4 hidden lg:block">
                                                <a
                                                    :href="order.invoice"
                                                    target="_blank"
                                                    class="font-Inter-Bold block download-invoice-link"
                                                    >Számla letöltése
                                                </a>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="flex flex-col gap-y-5 text-black">
                                        <div class="flex flex-col">
                                            <span
                                                class="block w-full border-b border-black border-opacity-10 mb-4 pb-2 text-left font-Inter-Bold"
                                                >Szállítási adatok</span
                                            >
                                            <div class="flex flex-col gap-y-3">
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Várható kiszállítás:</span>
                                                    <span class="block">{{ order.estimated_shipping_date }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Fizetési mód:</span>
                                                    <span class="block">{{ order.payment_method }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Szállítási mód:</span>
                                                    <span class="block">{{ order.shipping_method }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Név:</span>
                                                    <span class="block"
                                                        >{{ order.shipping_details.last_name }}
                                                        {{ order.shipping_details.first_name }}</span
                                                    >
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Email:</span>
                                                    <span class="block">{{ order.shipping_details.email }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Telefonszám:</span>
                                                    <span class="block">{{ order.shipping_details.phone }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Ország:</span>
                                                    <span class="block">{{
                                                        countries.find(
                                                            (country) => country.id === order.shipping_details.country
                                                        ).name
                                                    }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Cím:</span>
                                                    <span class="block"
                                                        >{{ order.shipping_details.zip_code }}
                                                        {{ order.shipping_details.city }}
                                                        {{ order.shipping_details.address }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            <span
                                                class="block w-full border-b border-black border-opacity-10 mb-4 pb-2 text-left font-Inter-Bold"
                                                >Számlázási adatok</span
                                            >
                                            <div class="flex flex-col gap-y-3">
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Név:</span>
                                                    <span class="block"
                                                        >{{ order.invoice_details.last_name }}
                                                        {{ order.invoice_details.first_name }}</span
                                                    >
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Email:</span>
                                                    <span class="block">{{ order.invoice_details.email }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Telefonszám:</span>
                                                    <span class="block">{{ order.invoice_details.phone }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Ország:</span>
                                                    <span class="block">{{
                                                        countries.find(
                                                            (country) => country.id === order.invoice_details.country
                                                        ).name
                                                    }}</span>
                                                </div>
                                                <div class="flex w-full justify-between text-sm">
                                                    <span class="block font-Inter-Bold">Cím:</span>
                                                    <span class="block"
                                                        >{{ order.invoice_details.zip_code }}
                                                        {{ order.invoice_details.city }}
                                                        {{ order.invoice_details.address }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="false" class="flex lg:flex-row flex-col gap-5">
                                            <div class="flex flex-col mb-4 w-full lg:w-1/2">
                                                <span
                                                    class="block w-full border-b border-black border-opacity-10 mb-4 pb-2 text-left font-Inter-Bold"
                                                    >Számla</span
                                                >
                                                <div class="w-full">
                                                    <a
                                                        class="download-invoice-button py-3 font-Inter-Bold hover:underline"
                                                        :href="order.invoice"
                                                        target="_blank"
                                                        >Számla letöltése</a
                                                    >
                                                </div>
                                            </div>
                                            <div class="flex flex-col mb-4 w-full lg:w-1/2">
                                                <span
                                                    class="block w-full border-b border-black border-opacity-10 mb-4 pb-2 text-left font-Inter-Bold"
                                                    >Kapcsolat</span
                                                >
                                                <div class="w-full">
                                                    <a
                                                        class="call-button py-3 pfont-Inter-Bold hover:underline"
                                                        href="tel:+36301781213"
                                                        >+36 (30) 178 1213</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            <span
                                                class="block w-full border-b border-black border-opacity-10 mb-4 pb-2 text-left font-Inter-Bold"
                                                >Megrendelt termékek</span
                                            >
                                            <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
                                                <nuxt-link
                                                    v-for="product in order.ordered_products"
                                                    :key="product.id"
                                                    :to="`termekek/${$slugify(product?.category)}/${$slugify(
                                                        product.related_categories[0].name
                                                    )}/${product?.slug}`"
                                                    class="flex flex-col gap-y-4 text-left col-span-1 justify-between"
                                                >
                                                    <div class="flex flex-col">
                                                        <div v-if="product?.preview" class="aspect-h-1 aspect-w-1">
                                                            <img
                                                                :src="product.preview"
                                                                :alt="product.name"
                                                                class="object-contain"
                                                            />
                                                        </div>
                                                        <span class="font-Inter-Bold text-[15px]">{{
                                                            product.name
                                                        }}</span>
                                                    </div>
                                                    <span class="font-Inter-Semibold block text-base opacity-80"
                                                        >{{ $formatCurrency(product.price.from) }}
                                                    </span>
                                                </nuxt-link>
                                            </div>
                                            <div
                                                class="bg-grey-300 bg-opacity-20 p-4 block w-full text-lg flex justify-between mt-8 w-[calc(100%+2rem)] -ml-4 -mb-10"
                                            >
                                                <span class="font-Inter-Bold block">Végösszeg:</span
                                                ><span class="block">{{ $formatCurrency(order.total) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div v-else-if="currentUserTab === 3" class="h-full">
                    <div v-if="liked_products.length">
                        <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
                            <div v-for="product in liked_products" :key="product.id" class="relative col-span-1">
                                <div @click="close">
                                    <nuxt-link
                                        :to="`termekek/${$slugify(product?.category)}/${$slugify(
                                            product.related_categories[0].name
                                        )}/${product?.slug}`"
                                        class="flex flex-col gap-y-4 text-left justify-between relative"
                                    >
                                        <div class="flex flex-col">
                                            <div class="aspect-h-1 aspect-w-1">
                                                <img
                                                    :src="$setImage(product.preview, 'products/')"
                                                    :alt="product.name"
                                                    class="object-contain"
                                                />
                                            </div>
                                            <span class="font-Inter-Bold text-[15px]">{{ product.name }}</span>
                                        </div>
                                        <div class="flex flex-col gap-y-1 mt-2">
                                            <div class="flex">
                                                <span class="font-Inter-Bold mr-1">Bruttó: </span>
                                                <span class="font-Inter-Semibold block text-base opacity-80"
                                                    >{{ $formatCurrency(product.brutto_price.from) }}
                                                </span>
                                            </div>
                                            <div class="flex">
                                                <span class="font-Inter-Bold mr-1">Nettó: </span>
                                                <span class="font-Inter-Semibold block text-base opacity-80"
                                                    >{{ $formatCurrency(product.price.from) }}
                                                </span>
                                            </div>
                                        </div>
                                    </nuxt-link>
                                </div>
                                <div
                                    class="absolute w-6 h-6 flex items-center rounded-full transition-all min-w-0 bg-no-repeat bg-center bg-contain bg-right w-full dislike-button cursor-pointer right-3 top-3 opacity-30 hover:opacity-100 z-10"
                                    @click="dislikeProduct(product)"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center mb-12 lg:mb-0 flex flex-col items-center justify-center h-full">
                        <img
                            :src="require('~/assets/images/icons/hearth-icon.svg')"
                            alt="Kedvec termékek"
                            class="w-12 h-auto mb-3"
                        />
                        <h4 class="font-Inter-Bold text-xl lg:text-[1.5rem] mb-4">
                            Még nem adtál hozzá kedvenc terméket
                        </h4>
                        <p class="lg:w-8/12 text-sm lg:text-base">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit Pariatur, perferendis?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import BaseModal from '~/components/UI/BaseModal.vue';
export default {
    name: 'UserModal',
    components: { BaseModal },
    props: {
        close: {
            type: Function,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            isUserModalOpen: false,
            userTabs: [
                {
                    id: 1,
                    name: 'Adataim',
                    icon: 'user',
                },
                {
                    id: 2,
                    name: 'Megrendeléseim',
                    icon: 'orders',
                },
                {
                    id: 3,
                    name: 'Kedvencek',
                    icon: 'likes',
                },
            ],
            currentUserTab: 1,
            countries: null,
            isErrorVisible: false,
            processedUserData: {},
        };
    },
    computed: {
        ...mapState(['liked_products']),
    },
    created() {
        /* await this.$axios
            .get('/api/user')
            .then((response) => {
                if (response) {
                    this.$auth.setUser(response.data[0]);
                    this.processedUserData = JSON.parse(JSON.stringify(this.$user));
                }
            })
            .catch((err) => {
                console.log(err);
            });
        await this.$axios
            .get('/api/countries')
            .then((response) => {
                if (response) {
                    this.countries = response.data;
                }
            })
            .catch((err) => {
                console.log(err);
            }); */
    },
    methods: {
        ...mapActions(['dislikeProduct']),

        closeUserModal() {
            this.isUserModalOpen = false;
        },
        changeTab(tab) {
            this.currentUserTab = tab;
        },
        changeUserData() {
            console.log('User data changed');
        },
        coloringStatus(status) {
            console.log(status);
            if (status === 'Folyamatban') {
                return 'text-yellow-100';
            } else if (status === 'Elküldve') {
                return 'text-green';
            } else if (status === 'Kiszállítva') {
                return 'text-green';
            } else if (status === 'Törölve') {
                return 'text-red-100';
            }
        },
    },
};
</script>

<style scoped>
.tab {
    &::before {
        content: '';
        @apply block w-5 h-5 bg-center bg-contain bg-no-repeat;
    }

    &--user {
        &::before {
            background-image: url('~/assets/images/icons/user-icon.svg');
        }
    }

    &--orders {
        &::before {
            background-image: url('~/assets/images/icons/orders-icon.svg');
        }
    }

    &--likes {
        &::before {
            background-image: url('~/assets/images/icons/hearts-icon.svg');
        }
    }

    &--active {
        @keyframes slideUp {
            from {
                @apply max-h-0;
            }
            to {
                @apply max-h-[5px];
            }
        }
        &::after {
            content: '';
            @apply w-full h-full max-h-0 block bg-blue-100 absolute bottom-0 left-0 transition-all;
            animation: slideUp 0.25s forwards;
        }
    }
}
.download-invoice-link {
    @apply flex items-center gap-x-2 transition hover:underline;
    &::before {
        content: '';
        @apply w-5 h-5 inline-block bg-center bg-contain bg-no-repeat;
        background-image: url('~/assets/images/icons/download-icon.svg');
    }
}

.download-invoice-button {
    @apply flex items-center gap-x-2 transition hover:bg-grey-25 bg-opacity-20;
    &::before {
        content: '';
        @apply w-6 h-6 inline-block bg-center bg-contain bg-no-repeat;
        background-image: url('~/assets/images/icons/download-icon.svg');
    }
}

.call-button {
    @apply flex items-center gap-x-2 transition hover:bg-grey-25 bg-opacity-20;
    &::before {
        content: '';
        @apply w-6 h-6 inline-block bg-center bg-contain bg-no-repeat;
        background-image: url('~/assets/images/icons/phone-icon.svg');
    }
}
.dislike-button {
    background-image: url('~/assets/images/icons/dislike-icon.svg');
}
</style>
