<template>
    <button
        class="w-7 h-7 relative flex flex-col justify-center items-center"
        :class="$store.state.globals.isMobileMenuOpen && 'is-open'"
        @click="toggleMobileMenu()"
    >
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
    </button>
</template>

<script>
import { enableBodyScroll, disableBodyScroll } from '@/static/scripts/body-scroll-lock.min';

export default {
    name: 'HamburgerButton',
    data() {
        return {
            isMenuOpen: false,
        };
    },
    watch: {
        '$store.state.isMobileMenuOpen': function (from, to) {
            if (from) {
                disableBodyScroll(document.querySelector('body'));
            } else {
                enableBodyScroll(document.querySelector('body'));
            }
        },
    },
    methods: {
        toggleMobileMenu() {
            this.$store.commit('globals/toggleMobileMenu', (this.isMenuOpen = !this.isMenuOpen));
        },
    },
};
</script>

<style scoped>
.bar {
    @apply w-[27px] absolute border-2 border-black origin-center transition;
    &:nth-of-type(1) {
        @apply translate-y-[8px];
    }
    &:nth-of-type(2) {
        @apply opacity-100;
    }
    &:nth-of-type(3) {
        @apply translate-y-[-8px];
    }
}

.is-open {
    .bar {
        &:nth-of-type(1) {
            @apply translate-y-0 rotate-45;
        }
        &:nth-of-type(2) {
            @apply opacity-0;
        }
        &:nth-of-type(3) {
            @apply translate-y-0 -rotate-45;
        }
    }
}
</style>
